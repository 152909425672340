import AsToast from '@/components/AsToast/asToast';
import i18n from '@/i18n';

const pako = require('pako');
// const { Buffer } = require('buffer');


/**
 * 格式输出
 */
export const toLog = (text) => {
    const needLog = process.env.REACT_APP_DEBUG_MODE + "";
    if (needLog === "YES") {
        console.log(text);
    }
}

/**
 * 判断对象是否为字符串
 * @param {Object} obj
 */
export const isString = obj => {
    return (typeof obj === "string")
}

/**
 * 判断对象是否为数组
 * @param {Object} obj
 */
export const isArray = obj => {
    return (Array.isArray(obj))
}

/**
 * 判断对象是否为空
 * @param {Object} obj
 */
export const isEmptyObject = obj => {
    for (let pro in obj) { return false }
    return true
}

/**
 * 判断对象是否为null
 * @param {Object} obj
 */
export const isNullObject = obj => {
    return !obj
}

/**
 * 判断字符串是否为空
 * @param {String} obj
 */
export const isEmptyString = obj => {
    if (isNullObject(obj)) {
        return true
    }
    if (!isString(obj)) {
        return true
    }
    if (obj.length <= 0) {
        return true
    }
    return false
}

/**
 * 判断字符串是否为必填
 * @param {String} obj
 */
export const isMustProofString = obj => {
    if (isNullObject(obj)) {
        return true
    }
    if (!isString(obj)) {
        return true
    }
    if (obj.length <= 0) {
        return true
    }
    if (obj === "YES") {
        return true
    }
    return false
}

/**
 * 判断状态是否为成功， 以 SUCCESS 结尾
 * @param {String} obj
 */
export const isStatusSuccess = obj => {
    if (!isString(obj)) {
        return false
    }
    const flag = "SUCCESS"
    const temp = obj + ""
    return (temp.endsWith(flag))
}

/**
 * 返回安全字符串
 * @param {String} obj
 */
export const toSafeString = obj => {
    if (isNullObject(obj)) {
        return ""
    }
    return obj
}

/**
 * 返回字符串列表，以指定格式分割
 * @param {String} obj
 */
export const toList = (obj, split) => {
    if (isNullObject(obj)) {
        return []
    }
    const splitFormat = isEmptyString(split) ? ":" : split
    return obj.split(splitFormat)
}

/**
 * 返回百分比字符串
 * @param {String} obj
 */
export const toPercentageString = (obj, fixed = 0) => {
    if (isNullObject(obj)) {
        return ""
    }
    if (obj.length === 0) {
        return ""
    }
    const floatNumber = parseFloat(obj);
    const percentage = (floatNumber * 100).toFixed(fixed);
    return (percentage + "%")
}

/**
 * 返回去掉首尾空串的字符串
 * @param {String} obj
 */
export const toTrimString = (obj) => {
    if (isString(obj)) {
        const trimStr = obj.replace(/^\s+|\s+$/g, "");
        return trimStr
    }
    return obj
}

/**
 * 返回标签类型字符串
 * @param {String} obj
 */
export const toEventTagString = (obj) => {
    if (isString(obj)) {
        const trimStr = toTrimString(obj);
        const noSpaceStr = trimStr.replace(" ", "_").toUpperCase();
        return noSpaceStr
    }
    return obj
}

/**
 * 返回当前系统类型
 * @returns {String} 系统类型
 */
export const checkOSType = () => {
    const userAgent = navigator.userAgent.toLowerCase()
    if (userAgent.indexOf("android") !== -1) {
        return "ANDROID"

    } else if ((userAgent.indexOf("iphone") !== -1) ||
        (userAgent.indexOf("ipad") !== -1) ||
        (userAgent.indexOf("ipod") !== -1) ||
        (userAgent.indexOf("ios") !== -1)) {
        return "IOS"

    } else {
        return "OTHER"
    }
}

export const daysDifference = (targetDateStr) => {
    const currentDate = new Date();
    const targetDate = new Date(targetDateStr);

    // 计算时间差（以毫秒为单位）
    const timeDiff = targetDate - currentDate;

    // 将毫秒转换为天数，向下取整
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    return daysDiff;
}

export const stringToGZIP = (str) => {
    if (!str) {
        return "";
    }
    try {
        // Encode string as UTF-8
        const utf8Encoder = new TextEncoder();
        const inputBytes = utf8Encoder.encode(str);

        // Compress the data using GZIP
        const compressedBytes = pako.gzip(inputBytes);

        // Convert compressed bytes to Base64
        const base64String = btoa(String.fromCharCode(...compressedBytes));

        return base64String;

    } catch (error) {
        console.error('Error compressing string:', error);
        return "";
    }
}

/**
 * 返回号码加密字符串
 * @param {String} obj
 */
export const toEllipsisMobile = (obj) => {
    if (isNullObject(obj)) {
        return ""
    }

    if (obj.length >= 6) {
        let start = obj.substring(0, 3)
        let end = obj.substring(obj.length - 3, obj.length)
        return (start + " *** " + end);
    }

    return obj;
}

/**
 * 复制字符串
 */
export const copyLabel = (text) => {

    if (!text || text.length === 0) return;

    const doPreVersionCopy = (content) => {
        try {
            const textArea = document.createElement('textArea')
            textArea.value = content
            textArea.style.width = 0
            textArea.style.position = 'fixed'
            textArea.style.left = '-999px'
            textArea.style.top = '10px'
            textArea.setAttribute('readonly', 'readonly')
            document.body.appendChild(textArea)
            textArea.focus()
            textArea.select()
            document.execCommand('copy')
            document.body.removeChild(textArea)

            const copySuccessfully = i18n.t('copySuccessfully');
            AsToast.show(copySuccessfully);

        }
        catch (error) {
            AsToast.show('Unable to copy content to clipboard');
        }
    }

    if (navigator.clipboard && navigator.permissions) {
        navigator.clipboard.writeText(text)
            .then(function () {
                const copySuccessfully = i18n.t('copySuccessfully');
                AsToast.show(copySuccessfully);
            })
            .catch(function (err) {
                doPreVersionCopy(text);
            });
    } else {
        doPreVersionCopy(text);
    }
}

/**
 * 拨打电话
 */
export const dialCall = (phone = "") => {
    if (phone.length === 0) return;

    window.location.href = `tel:${phone}`
}

/**
 * 是否json字符串
 */
export const isJSONText = (text) => {
    try {
        JSON.parse(text);
        return true;
    } catch (e) {
        return false;
    }
}

/**
 * 删除尾部 *
 */
export const removeTrailingAsterisk = (str = "") => {
    if (!str) return "";
    return str.endsWith('*') ? str.slice(0, -1) : str;
};