
// export const BaseUrl = "http://localhost:3000/cashwave"

import DefaultLang from "@/useLanguage";
import { toLog } from "./utils";

export const BaseUrl = process.env.REACT_APP_API_BASE_URL;
toLog("baseUrl: " + BaseUrl)

//获取转发信息
export const UrlGetZonePath = "/allproductapkzone"

//获取配置信息
export const UrlGetAppConfig = "/v1/getAppShowInfo"

//获取验证码
export const UrlGetOtp = "/v1/smsCode"

//获取申请验证码
export const UrlGetApplyOtp = "/v1/loanApplySms"

//获取验证码-新版本whatsapp
export const UrlSendWhatAppMessage = "/v1/sendWhatAppMessage"

//验证码登录
export const UrlLogin = "/v1/userLogin"

//fb登录
export const UrlLoginFacebook = "/v2/facebookUnionLogin"

//获取首页额度
export const UrlGetLoanLimit = "/v1/loanLimit"

//获取用户状态
export const UrlGetUseState = "/v1/userProcess"

//保存紧急联系人
export const UrlSaveProofContactList = "/v1/contactList"

//获取BankCode列表
export const UrlGetBankCodeList = "/v1/fetchBankCodes"

//获取贷款信息-申请页使用
export const UrlGetLoanDetail = "/v1/getLoanApplyDetail"

//获取金额试算信息-申请页使用
export const UrlGetLoanApplyDetailByAmount = "/v1/getLoanApplyDetailByAmount"

//获取金额试算信息-申请页使用
export const UrlGetLoanDetailPageForDays = "/v1/getLoanDetailPageForDays"

//确认申请贷款-申请页使用
export const UrlConfirmLoanApply = "/v1/loanApply"

//获取还款信息-还款页使用
export const UrlGetReapyDetail = "/v1/repayInfo"

//获取还款链接-还款页使用
export const UrlGetReapyUrl = "/v1/getOrderRepayH5"
export const UrlGetReapyUrlKsh = "/v1/getOrderRepayH5"

//pin还款状态
export const UrlQueryPaymentStatus = "/v1/queryPaymentStatus"

//记录埋点
export const UrlSaveEvent = "/v1/userBuriedPoint"

//保存aduid
export const UrlSaveAduid = "/v1/saveAduid"

//获取运营商验证码
export const UrlGetDataSmsCode = "/v1/fetchDataSmsCode"

//运营商验证码登录
export const UrlDataSmsCodeLogin = "/v1/fetchDataUserLogin"

//保存app列表
export const UrlSaveFrequentlyApps = "/v1/saveData"

//保存位置信息
export const UrlSaveUserLocation = "/v1/userLocation"

//上传图片
export const UrlUploadImage = "/v1/imageUpload"

//保存人脸信息
export const UrlSaveFaceInfo = "/v1/ocrInfo"

//保存证件信息
export const UrlSaveOcrInfo = "/v1/ocrInfo"

//保存fb信息息
export const UrlSaveFacebookInfo = "/v2/facebookUnionSave"

//发送失败短信
export const UrlSendContractSms = "/v1/sendContractSms"

//获取钱包列表
export const UrlFetchBoundBankAccount = "/v2/fetchBoundBankAccount"

//绑钱包
export const UrlBindBankAccount = "/v2/bindBankAccount"

//删钱包
export const UrlDeleteBoundBankAccount = "/v1/deleteBoundBankAccount"

//保存其它贷款信息
export const UrlSaveProofOtherLoansInfo = "/v1/extraInfo"

// web-push save
export const UrlWebPushSave = "/v1/saveWebPushRecord"

//保存内存信息
export const UrlSaveDeviceInfo = "/v1/deviceInfo"

//获取产品列表
export const UrlQueryNewStyleProducts = "/v1/queryNewStyleProducts"

//复制产品
export const UrlOneKeyCopyToApply = "/v1/oneKeyCopyToApply"

//查询用户钱包列表
export const UrlGetBindCardList = "/v1/getBindCardList"

//查询拒绝后推荐产品列表
export const UrlUserDeniedDc = "/v1/userDeniedDc"

//保存用户反馈
export const UrlFeedback = "/v1/feedback"

//无验证码登录
export const UrlUserLoginNoCode = "/v1/userLoginNoCode"

//获取绑卡验证码
export const UrlBindBankAccountSmsCode = "/v1/bindBankAccountSmsCode"

//获取用户信息
export const UrlGetSimpleData = "/v1/recommendSimpleData"

export const toFullApi = apiUrl => {
    // let url = "/front" + apiUrl;
    // return url;
    return DefaultLang.getApiPath() + apiUrl;
}

/**
 * 转换为未映射接口
 * @param {String} apiUrl
 */
export const toUnRefApi = apiUrl => {
    switch (apiUrl) {
        //基本信息
        case "/cashwave/v1/basicInfo": return "/v1/basicInfo"
        //联系人信息
        case "/cashwave/v1/contactList": return "/v1/contactList"
        //工作信息
        case "/cashwave/v1/workInfo": return "/v1/workInfo"
        //bvn信息
        case "/cashwave/v1/bvnInfo": return "/v1/bvnInfo"
        //银行卡信息
        case "/cashwave/v1/bindBankAccount": return "/v1/bindBankAccount"

        default: return apiUrl
    }
}